import { Box, CardMedia, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';

import { useCart } from 'hooks';
import React, { FC, useCallback } from 'react';

import Container, { MAX_CONTAINER_WIDTH, MIN_TABLET_WIDTH } from 'ui/common/Container';
import CartUniqueItemsCount from 'ui/common/icons/CartUniqueItemsCount';

import Typography from 'ui/common/Typography';
import UserMenu from 'ui/desktop/UserMenu';

import useStyles from './styles';

import { ISmallWebsiteMobileHeader, TOnImageError } from './types';

const SmallWebsiteMobileHeader: FC<ISmallWebsiteMobileHeader> = (props) => {
  const { isTransparent, imageUrl, websiteDetails, userMenuProps, basketCount = 0 } = props;

  const classes = useStyles({ isTransparent });

  const { handleContinueToCart } = useCart();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH));

  const onImageError = useCallback<TOnImageError>((e) => {
    (e.target as HTMLImageElement).src = '/assets/images/store-logo-on-error.svg';
  }, []);

  return (
    <>
      <Box className={classes.wrapper}>
        <Container>
          <Box className={classes.content}>
            <Box className={classes.header}>
              {!isTransparent && (
                <Box className={classes.labelSection}>
                  <Box className={classes.logoWrapper}>
                    <CardMedia
                      classes={{ root: classes.logo }}
                      component="img"
                      image={imageUrl}
                      alt={websiteDetails.name}
                      onError={onImageError}
                    />
                  </Box>
                  <Typography
                    fontWeight="bold"
                    color="light"
                    variant="h2"
                    className={classes.storeName}
                  >
                    {websiteDetails.name}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box display="flex" width="fit-content" alignItems="center" maxHeight={36}>
              {isTablet && (
                <Box className={classes.basketSection}>
                  <CartUniqueItemsCount
                    count={basketCount}
                    iconFontSize="default"
                    textFontSize={10}
                    textColor="secondary"
                    iconClasses={{ root: classNames({ [classes.basketIcon]: !basketCount }) }}
                    onIconClick={handleContinueToCart}
                  />
                </Box>
              )}
              <UserMenu
                size="mobile"
                isHideText
                {...userMenuProps}
                overrideClasses={{
                  menuList: classes.menuList,
                  clientCouponWrapper: classes.clientCouponWrapper,
                  menuItem: classes.menuItem,
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SmallWebsiteMobileHeader;
