import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CustomImageLoader from 'components/common/CustomImageLoader';
import { useMobile } from 'hooks';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'ui/common/Container';
import Typography from 'ui/common/Typography';
import useStyles from './styles';
import { ISmallWebsiteBigHeader } from './types';

const SmallWebsiteBigHeader: FC<ISmallWebsiteBigHeader> = ({ storeName, imageUrl }) => {
  const websiteDetails = useWebsiteDetails();

  const { t } = useTranslation();

  const { isMobile } = useMobile();

  const classes = useStyles();

  const storeLogo = useMemo(() => {
    return (
      <Box className={classes.logoWrapper}>
        {imageUrl && (
          <CustomImageLoader
            src={imageUrl}
            errorImageSrc="/assets/images/store-logo-on-error.svg"
            alt={t('shoppingPage.goToHomePage', { storeName: websiteDetails.name })}
            layout="fixed"
            width={isMobile ? 130 : 160}
            height={isMobile ? 130 : 160}
            loaderComponent={
              <Skeleton
                animation="wave"
                variant="circle"
                classes={{ root: classes.logoSkeleton }}
              />
            }
            classes={classes.logo}
            priority
            unoptimized
          />
        )}
      </Box>
    );
  }, [
    classes.logo,
    classes.logoSkeleton,
    classes.logoWrapper,
    imageUrl,
    isMobile,
    websiteDetails.name,
    t,
  ]);

  return (
    <Container fixed maxContainerWidth>
      <Box className={classes.wrapper}>
        {storeLogo}
        <Box className={classes.storeDetailWrapper}>
          <Typography isTranslate={false} variant="h2" color="light" className={classes.storeName}>
            {storeName}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SmallWebsiteBigHeader;
