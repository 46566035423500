import { Box } from '@material-ui/core';
import classNames from 'classnames';
import SkeletonMainLayoutBackgroundImage from 'components/common/skeletons/SkeletonMainLayoutBackgroundImage';
import SmallWebsiteBigHeader from 'components/common/SmallWebsiteContent/components/headers/SmallWebsiteBigHeader';
import SmallWebsiteMobileHeader from 'components/common/SmallWebsiteContent/components/headers/SmallWebsiteMobileHeader';

import { useFooter, useHeader, useMobile, useWebsiteDetails } from 'hooks';
import { useMainLayout } from 'hooks/useMainLayout';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import i18next from 'i18next';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configActions } from 'store';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import { TRenderHeader } from 'ui/common/layout/MainLayout';
import StickyHeader from 'ui/common/layout/MainLayout/components/StickyHeader';
import OrderModeIndicator from 'ui/common/OrderModeIndicator';
import DesktopFooter from 'ui/desktop/Footer';

import DesktopHeader from 'ui/desktop/Header';
import MobileFooter from 'ui/mobile/Footer';
import { ISmallWebsiteMainLayout } from './types';

import useStyles from './styles';

const SmallWebsiteMainLayout: FC<ISmallWebsiteMainLayout> = ({ showBigHeader, children }) => {
  const websiteDetails = useWebsiteDetails();

  const { isMobile } = useMobile();

  const { relevantLanguages } = useMultiLanguage();

  const dispatch = useDispatch();

  const { onMainHeaderObserve, onLoadBackgroundImage, getBackgroundImage } = useMainLayout();
  const orderMode = useSelector(getOrderMode);

  // header logic
  const { getDesktopHeaderProps, getMobileHeaderProps } = useHeader();

  // footer logic
  const { getDesktopFooterProps, getMobileFooterProps } = useFooter();

  const [loadingBackgroundImage, setLoadingBackgroundImage] = useState<boolean>(!!showBigHeader);

  const classes = useStyles({ orderMode });

  useEffect(() => {
    if (getBackgroundImage()) {
      onLoadBackgroundImage(() => setLoadingBackgroundImage(false));
    }
  }, [getBackgroundImage, onLoadBackgroundImage]);

  useEffect(() => {
    const active =
      relevantLanguages.filter((language) => language.shortName === i18next.language)[0] ||
      relevantLanguages[0];
    dispatch(configActions.updateLanguageConfigAction(active.shortName));
  }, [dispatch, relevantLanguages]);

  useEffect(() => {
    if (showBigHeader && !loadingBackgroundImage) {
      onMainHeaderObserve();
    }
  }, [showBigHeader, loadingBackgroundImage, onMainHeaderObserve]);

  const renderHeader = useMemo<TRenderHeader>(
    () => (mainHeaderProps) => {
      return (
        <>
          {isMobile ? (
            <SmallWebsiteMobileHeader {...getMobileHeaderProps('smallEC')} {...mainHeaderProps} />
          ) : (
            <DesktopHeader {...getDesktopHeaderProps('smallEC')} {...mainHeaderProps} />
          )}
        </>
      );
    },
    [getDesktopHeaderProps, getMobileHeaderProps, isMobile],
  );

  return (
    <>
      <Box className={classes.root}>
        {!loadingBackgroundImage && (
          <StickyHeader ariaHidden>
            {orderMode !== 'new' && <OrderModeIndicator orderMode={orderMode} />}
            {renderHeader()}
          </StickyHeader>
        )}
        <Box component="main">
          {showBigHeader && (
            <Box
              component="section"
              className={classNames(classes.topBlockWrapper, 'mui-fixed')}
              id="mainHeader"
            >
              {orderMode !== 'new' && <OrderModeIndicator orderMode={orderMode} />}
              <Box id="bg-img" className={classes.topBlock}>
                {loadingBackgroundImage ? (
                  <SkeletonMainLayoutBackgroundImage />
                ) : (
                  <>
                    {renderHeader({
                      isTransparent: true,
                      isHideLogo: true,
                      websiteDetails,
                      imageUrl: '',
                    })}
                    <SmallWebsiteBigHeader
                      storeName={websiteDetails.name}
                      imageUrl={websiteDetails.logoPath}
                    />
                  </>
                )}
              </Box>
            </Box>
          )}
          {showBigHeader ? (
            <Box component="section" className={classes.mainBlock}>
              {children}
            </Box>
          ) : (
            children
          )}
        </Box>
        <Box component="footer" id="footer" zIndex={5}>
          {isMobile ? (
            <MobileFooter {...getMobileFooterProps()} />
          ) : (
            <DesktopFooter {...getDesktopFooterProps()} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default SmallWebsiteMainLayout;
